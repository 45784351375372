import { getField, updateField } from 'vuex-map-fields'
import requests from '~/requests'
import { ROOMS_URL, CONTACTS_OBJECT } from '@/assets/variables/endpoints'

export const state = () => ({
  igPost: {
    id: '',
    ig_id: '',
    caption: '',
    comments_count: null,
    is_comment_enabled: true,
    like_count: null,
    media_product_type: '',
    media_type: '',
    media_url: '',
    owner: {
      id: '',
    },
    permalink: '',
    shortcode: '',
    thumbnail_url: '',
    timestamp: '',
    username: '',
  },
  igContactDetails: {
    id: '',
    contact_handler_id: '',
    phone_number: '',
    full_name: '',
    email: '',
    username: '',
    authority: '',
    code: '',
    created_at: '',
    updated_at: '',
    last_activity_at: '',
    channel: '',
    status: '',
    error_messages: {},
    extra: {
      email: '',
      username: '',
    },
    account_uniq_id: null,
    channel_integration_id: '',
    avatar: {},
    is_valid: null,
    is_blocked: null,
    childs: [],
  },
})

export const getters = {
  getField,
}

export const actions = {
  getDetailPostIg({ commit, state }, params) {
    commit('CLEAR_IG_POST')
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${ROOMS_URL}/${params.room_id}/ig_post_detail`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('SET_IG_POST', res.data)
            resolve(res)
          },
          (err) => {
            commit(
              'layouts/DISPLAY_TOAST_PIXEL',
              {
                title: err.error.messages[0],
                variant: 'error',
              },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  getIgCommentUser({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${CONTACTS_OBJECT}/${params.id}`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('UPDATE_IG_CONTACT_DETAILS', res.data)
            resolve(res)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
}

export const mutations = {
  updateField,
  SET_IG_POST(state, payload) {
    state.igPost = payload
  },
  UPDATE_IG_CONTACT_DETAILS(state, payload) {
    state.igContactDetails = payload
  },
  CLEAR_IG_POST(state, payload) {
    state.igPost.id = ''
    state.igPost.ig_id = ''
    state.igPost.caption = ''
    state.igPost.comments_count = null
    state.igPost.is_comment_enabled = true
    state.igPost.like_count = null
    state.igPost.media_product_type = ''
    state.igPost.media_type = ''
    state.igPost.media_url = ''
    state.igPost.owner = {}
    state.igPost.permalink = ''
    state.igPost.shortcode = ''
    state.igPost.thumbnail_url = ''
    state.igPost.timestamp = ''
    state.igPost.username = ''
  },
}
