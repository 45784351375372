import { getField, updateField } from 'vuex-map-fields'
import requests from '../requests'
import { TOKEN_UPDATE_URL } from '@/assets/variables/endpoints'

let indexedDBCount = 0

export const state = () => ({
  notificationPermission: false,
  fcmToken: localStorage.getItem('subscribe_token'),
})

export const getters = {
  getField,
}

export const actions = {
  getBrowserNotificationPermission({ commit }) {
    return new Promise((resolve) => {
      if (typeof Notification === 'undefined' || !Notification) {
        return resolve(false)
      }
      Notification &&
        Notification.requestPermission().then((permission) => {
          if (permission === 'granted') {
            commit('UPDATE_NOTIFICATION_PERMISSION', true)
            resolve(true)
          } else {
            commit('UPDATE_NOTIFICATION_PERMISSION', false)
            resolve(false)
          }
        })
    })
  },
  getFcmRegistrationToken({ commit, dispatch, state }, params) {
    if (params) {
      const localToken = localStorage.getItem('subscribe_token')
      localStorage.setItem('subscribe_token', params)
      dispatch('updateGeneratedFcmToken', params)
        .then(() => {
          commit('UPDATE_FCM_TOKEN', params)
          if (localToken !== params && localToken) {
            dispatch('removePreviousFcmToken', { token: localToken })
          }
        })
        .catch(() => {
          commit('UPDATE_FCM_TOKEN', localStorage.getItem('subscribe_token'))
        })
    }
  },
  updateGeneratedFcmToken({ dispatch }, params) {
    const param = {
      resource_type: 'web',
      token: params,
    }
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .putService(`${TOKEN_UPDATE_URL}`, param, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            indexedDBCount = 0
            resolve(true)
          },
          (err) => {
            indexedDBCount++
            if (err.error.code === 422 && indexedDBCount < 4) {
              removeFcmIndexedDb()
            }
            reject(err)
          }
        )
    })
  },
  removePreviousFcmToken({ dispatch }, params) {
    const param = {
      resource_type: 'current',
      token: params.token,
    }
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .deleteService(`${TOKEN_UPDATE_URL}`, param, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            resolve(true)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  onTokenRefreshListener({ commit, dispatch }, params) {
    return new Promise((resolve, reject) => {
      // TODO: Mencari cara untuk implementasi onTokenRefresh di Firebase Native
      this.$fire.messaging.onTokenRefresh((token) => {
        localStorage.setItem('subscribe_token', token)
        dispatch('updateGeneratedFcmToken', { token })
          .then(() => {
            indexedDBCount = 0
            commit('UPDATE_FCM_TOKEN', token)
            dispatch('removePreviousFcmToken', { token })
          })
          .catch((err) => {
            indexedDBCount++
            if (err.error.code === 422 && indexedDBCount < 4) {
              removeFcmIndexedDb()
            }
            commit('UPDATE_FCM_TOKEN', localStorage.getItem('subscribe_token'))
          })
      })
    })
  },
}

export const mutations = {
  updateField,
  UPDATE_NOTIFICATION_PERMISSION(state, payload) {
    state.notificationPermission = payload
  },
  UPDATE_FCM_TOKEN(state, payload) {
    state.fcmToken = payload
  },
}

const removeFcmIndexedDb = () => {
  /**
   *
   * @description To clear fcm token cache
   */
  /* eslint-disable no-console */
  try {
    return new Promise((resolve, reject) => {
      if (!window.indexedDB) return
      let db
      function closeDatabase() {
        db.close()
      }
      function createDatabase() {
        const request = window.indexedDB.open('firebase-messaging-database', 1)
        request.onsuccess = (e) => {
          db = request.result
          const tr = db.transaction('firebase-messaging-store', 'readwrite')
          const os = tr.objectStore('firebase-messaging-store')
          const cl = os.clear()
          cl.onsuccess = () => {
            closeDatabase()
            resolve()
          }
          cl.onerror = (e) => {
            closeDatabase()
            reject(e)
          }
        }
      }
      createDatabase()
    })
  } catch (e) {
    console.error(e)
  }
}
