import { getField, updateField } from 'vuex-map-fields'

export const state = () => ({
  meta: {},
})

export const getters = {
  getField,
}

export const actions = {}

export const mutations = {
  updateField,
  SET_META(state, payload) {
    state.meta[payload.source] = payload.data
  },
  CLEAR_META(state, payload) {
    state.meta[payload.source] = {}
  },
}
