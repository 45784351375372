import _ from 'lodash-core'
import { getField, updateField } from 'vuex-map-fields'
import moment from 'moment'
import requests from '../requests'
import { USER_CORE_URL, BILLINGS_URL } from '@/assets/variables/endpoints'
import reportingAccess from '~/utils/reporting.js'

export const state = () => ({
  heightBannerMaintenance: 0,
  heightBannerUserStatus: 0,
  heightBannerCreditBalance: 0,
  bgColor: '',
  token: '',
  pageTitle: '',
  loadingbar: false,
  bannerMau: {},
  notification_sound: true,
  notification: {
    display: false,
    type: '',
    message: '',
    item: '',
    subItem: '',
    closeable: false,
    callback: {
      text: '',
      method: '',
    },
  },
  userBanks: [],
  userStatus: {
    is_online: false,
    status: '',
    timestamp: null,
  },
  contentLoading: {
    content: false,
    content2: false,
    content3: false,
    table: false,
  },
  backBtn: false,
  meta: {
    pagination: 0,
  },
  searchRoomMeta: {},
  incoming_notification: {
    inbox: [],
    others: [],
  },
  browser_type: '',
  downtime: null,
  totalCount: 0,
  reportFilter: {
    time_offsets: 7,
    relative_filter: null,
    target_channel: null,
    start_date: moment(new Date()).format('YYYY-MM-DD'),
    end_date: moment(new Date()).format('YYYY-MM-DD'),
  },
  reportDateRange: [new Date(), new Date()],
  reportDateRangeName: {
    title: 'Today',
    label: 'today',
  },
  broadcast_limit: 1000,
  contactObjectViewData: {
    show_modal: false,
    show_modal_removehandlers: false,
    params: {},
  },
  reportingAccess,
  chatbotMode: {
    isEditing: false,
    popUp: false,
    trigger: '',
  },
  appConfig: null,
  ssoToken: null,
  isAppchatAccessible: false,
  stickyToast: {
    isShow: false,
    message: '',
    iconName: 'info',
    iconVariant: 'duotone',
    variant: 'info',
    duration: 3000,
  },
  kbInfo: {
    kb_client_url: null,
    kb_token: null,
    kb_active: false,
  },
})

export const getters = {
  getField,
}

export const actions = {
  getBannerMau({ commit, state }, queryParam) {
    return new Promise((resolve, reject) => {
      const headers = { Authorization: this.$auth.getToken('hub') }
      requests.webchat
        .getService(
          `${BILLINGS_URL}/balance_remaining_status`,
          queryParam,
          headers
        )
        .subscribe(
          async (res) => {
            if (res.data && Object.keys(res.data).length > 0) {
              await commit('SET_BANNER_MAU', res.data)
            }
            resolve(res.data)
          },
          (err) => {
            reject(err)
          }
        )
    })
  },
  createNewUserValueOnRDB({ commit, state }, params) {
    const onlineStatus = {
      isOnline: true,
      status: 'online',
      timestamp: new Date().getTime(),
    }
    this.$fireDb
      .ref(`status/${this.$auth.user.organization_id}/${this.$auth.user.id}`)
      .set(onlineStatus, (error) => {
        if (!error) {
          this.$store.commit('layouts/UPDATE_USER_STATUS', {
            isOnline: onlineStatus.isOnline,
            status: onlineStatus.status,
            timestamp: onlineStatus.timestamp,
          })
        }
      })
  },
  updateUserValueOnRDB({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      this.$fireDb
        .ref(`status/${this.$auth.user.organization_id}/${this.$auth.user.id}`)
        .update(params)
        .then(() => {
          commit('UPDATE_USER_STATUS', params)
          resolve(true)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  // api version of updateUserValueOnRDB
  updateUserStatus({ commit, state }, params) {
    const headers = { Authorization: this.$auth.getToken('hub') }
    const sentParams = {
      ...params,
      timestamp: new Date().getTime(),
      device: 'web',
    }

    requests.whatsapp
      .postService(`${USER_CORE_URL}/online_status`, sentParams, headers)
      .subscribe(
        (res) => {
          const isOnline = {
            is_online: res.data.is_online,
            status: res.data?.is_online ? 'online' : 'offline',
          }
          commit('UPDATE_USER_STATUS', isOnline)
        },
        (err) => {
          const isOnline = {
            is_online: !params.is_online,
            status: !params.is_online ? 'online' : 'offline',
          }
          commit('UPDATE_USER_STATUS', isOnline)
          commit(
            'layouts/UPDATE_NOTIFICATION',
            {
              display: true,
              type: 'failed',
              message: 'Error!',
              item: err?.error?.messages?.[0] || err,
              callback: {
                text: 'OK',
                method: 'closeNotification',
              },
            },
            { root: true }
          )
        }
      )
  },
  getDowntimeInfo({ commit, state }, params) {
    requests.downtime
      .downtime({ token: this.$auth.getToken('auth') || '' })
      .subscribe((response) => {
        commit('UPDATE_DOWNTIME_INFO', response)
      })
  },
  getConfiguration({ commit }) {
    return new Promise((resolve) => {
      this.$axios
        .get(`${process.env.HUB_SERVICE_URL}/api/core/v1/client_configs/config`)
        .then(({ data: res }) => {
          const config = res.data
          if (process.env.NODE_ENV === 'development') {
            config.sso.status = false
          }
          commit('UPDATE_APP_CONFIGURATION', config)
          resolve(res.data)
        })
    })
  },
  updateBgColor({ commit, state }, params) {
    commit('UPDATE_BG_COLOR', params)
  },
}

export const mutations = {
  updateField,
  UPDATE_NOTIFICATION_BELL(state, payload) {
    if (payload.notification_type === 'inbox') {
      // If notification comes from conversation
      if (!_.some(state.incoming_notification.inbox, payload))
        state.incoming_notification.inbox.unshift(payload)
    }
    if (payload.notification_type !== 'inbox') {
      // If notification comes from others
      if (!_.some(state.incoming_notification.others, payload))
        state.incoming_notification.others.unshift(payload)
    }
  },
  UPDATE_NOTIFICATION_READ(state, payload) {
    for (
      let index = 0;
      index < state.incoming_notification[payload.type].length;
      index++
    ) {
      if (
        state.incoming_notification[payload.type][index].room.id ===
        payload.room.id
      ) {
        state.incoming_notification[payload.type][index].read = true
      }
    }
  },
  UPDATE_NOTIFICATION_CLICKABLE(state, payload) {
    for (
      let index = 0;
      index < state.incoming_notification[payload.type].length;
      index++
    ) {
      if (
        state.incoming_notification[payload.type][index].room.id ===
        payload.room.id
      ) {
        state.incoming_notification[payload.type][index].clickable = false
      }
    }
  },
  DELETE_INBOX_NOTIFICATION_AFTER_HANDOVER(state, payload) {
    const filteredData = _.filter(
      state.incoming_notification.inbox,
      function (data) {
        return data.room.id !== this.roomId
      }.bind({ roomId: payload.id })
    )
    state.incoming_notification.inbox = filteredData
    // const notificationIndex = _.findIndex(state.incoming_notification.inbox, { message_type: 'handover', room: payload.room })
    // state.incoming_notification.inbox.splice(notificationIndex, 1)
  },
  DELETE_ALL_NOTIFICATIONS(state, payload) {
    state.incoming_notification = {
      inbox: [],
      others: [],
    }
  },
  PAGE_TITLE(state, params) {
    state.pageTitle = params
  },
  UPDATE_LOADINGBAR(state, params) {
    state.loadingbar = params
  },
  UPDATE_NOTIFICATION(state, params) {
    if (params.item) {
      if (typeof params.item.error !== 'undefined') {
        if (
          typeof params.item.error.message !== 'undefined' &&
          params.item.error.message.includes('Application request limit')
        ) {
          params.item =
            'You currently cannot do this action due to the a rate limit set by Facebook. Please try again in few minutes for this rate limit to pass.'
        }
      } else if (
        Object.keys(params.item).length === 0 &&
        params.item.includes('Application request limit')
      ) {
        params.item =
          'You currently cannot do this action due to the a rate limit set by Facebook. Please try again in few minutes for this rate limit to pass.'
      }
    } else {
      params.item = ''
    }

    params.closeable =
      typeof params.closeable === 'undefined' ? false : params.closeable
    state.notification = params
  },
  SET_META(state, params) {
    state.meta = params
  },
  SET_TOTAL_COUNT(state, params) {
    state.totalCount = params
  },
  SET_SEARCH_ROOM_META(state, params) {
    state.searchRoomMeta = params
  },
  UPDATE_CONTENTLOADING(state, params) {
    state.contentLoading[params.type] = params.status
  },
  SET_BACKBUTTON(state, params) {
    state.backBtn = params
  },
  DISPLAY_TOAST(state, params) {
    this._vm.$bvToast.toast(params.message, {
      toaster: 'b-toaster-top-center middle-toast',
      autoHideDelay: 5000,
      headerClass: 'd-none',
      appendToast: false,
      toastClass: 'mt-4',
      bodyClass: 'h6 mb-0',
    })
  },
  DISPLAY_MESSAGE_NOTIFICATION(state, params) {
    let channelIcon = ''
    switch (params.channel.toLowerCase()) {
      case 'wa':
        channelIcon = 'lab la-whatsapp text-success'
        break
      case 'wa_cloud':
        channelIcon = 'lab la-whatsapp text-success'
        break
      case 'website':
        channelIcon = 'las la-laptop text-danger'
        break
      case 'fb':
        channelIcon = 'lab la-facebook-messenger text-primary'
        break
      case 'telegram':
        channelIcon = 'lab la-telegram text-primary'
        break
      case 'email':
        channelIcon = 'las la-envelope text-danger'
        break
      case 'livechat_dot_com':
        channelIcon = 'las la-comment-alt text-livechat_dot_com text-info'
        break
      case 'twitter':
        channelIcon = 'lab la-twitter text-primary'
        break
      case 'ig':
        channelIcon = 'lab la-instagram text-info'
        break
      case 'ig_comment':
        channelIcon = 'lab la-instagram text-info'
        break
      case 'line':
        channelIcon = 'lab la-line text-success'
        break
      case 'app_chat':
        channelIcon = 'lab la-comment text-primary'
        break
    }
    const h =
      this.$createElement !== undefined
        ? this.$createElement
        : this._vm.$createElement
    const vNodesTitle = h('div', { class: `centered-item` }, [
      h('i', { class: `${channelIcon} mr-1 fs-16` }),
      params.sender,
    ])
    const messages =
      params.message.length > 100
        ? params.message.substring(0, 100) + '...'
        : params.message
    this._vm.$bvToast.toast(messages, {
      title: vNodesTitle,
      toaster: 'b-toaster-top-center middle-toast',
      headerClass: 'fw-500',
      autoHideDelay: 5000,
      appendToast: true,
      toastClass: 'mt-4',
      bodyClass: 'fw-500 mb-0',
      to: { name: 'inbox', query: { room: params.room_id } },
    })
  },
  DISPLAY_RIGHT_TOAST(state, params) {
    this._vm.$bvToast.toast(params.message, {
      title: params.from,
      toaster: 'b-toaster-top-right',
      variant: 'light',
      autoHideDelay: 5000,
      toastClass: 'm-3',
      appendToast: false,
      to: { name: 'inbox', query: { room: params.room_id } },
    })
  },
  UPDATE_INCOMING_NOTIFICATION_STATUS(state, params) {
    state.notification_sound = params
  },
  BROWSER_TYPE_UPDATE(state) {
    if (navigator.userAgent.includes('Opera')) {
      state.browser_type = 'Opera'
    } else if (navigator.userAgent.includes('Firefox')) {
      state.browser_type = 'Firefox'
    } else if (
      navigator.userAgent.includes('Safari') &&
      !navigator.userAgent.includes('Chrome')
    ) {
      state.browser_type = 'Safari'
    } else if (
      navigator.userAgent.includes('MSIE') ||
      navigator.userAgent.includes('Trident')
    ) {
      state.browser_type = 'Internet Explorer'
    } else if (navigator.userAgent.includes('Edge')) {
      state.browser_type = 'Edge'
    } else if (
      navigator.userAgent.includes('Chrome') &&
      navigator.userAgent.includes('Safari')
    ) {
      state.browser_type = 'Chrome'
    } else if (navigator.userAgent.includes('Chromium')) {
      state.browser_type = 'Chromium Edge'
    } else if (navigator.userAgent.includes('Blink')) {
      state.browser_type = 'Blink Engine'
    }
  },
  UPDATE_NOTIFICATION_PERMISSION(state, payload) {
    state.notificationPermission = payload
  },
  UPDATE_USER_STATUS(state, payload) {
    state.userStatus = payload
  },
  UPDATE_USER_STATUS_BANKS(state, payload) {
    state.userBanks = payload
  },
  UPDATE_DOWNTIME_INFO(state, payload) {
    state.downtime = payload
  },
  UPDATE_REPORT_FILTER(state, payload) {
    state.reportFilter = payload
  },
  UPDATE_REPORT_FILTER_DATE_RANGE(state, payload) {
    state.reportDateRange = payload
  },
  UPDATE_REPORT_FILTER_DATE_RANGE_NAME(state, payload) {
    state.reportDateRangeName = payload
  },
  UPDATE_BG_COLOR(state, payload) {
    state.bgColor = payload
  },
  UPDATE_MODAL_ADD_HANDLERS(state, payload) {
    state.contactObjectViewData.params = payload
    state.contactObjectViewData.show_modal = true
  },
  UPDATE_CONTACT_OBJECT_MODAL(state, payload) {
    state.contactObjectViewData.show_modal = payload
    state.contactObjectViewData.show_modal_removehandlers = payload
  },
  UPDATE_MODAL_REMOVEHANDLERS(state, payload) {
    state.contactObjectViewData.params = payload.params
    state.contactObjectViewData.show_modal_removehandlers =
      payload.show_modal_removehandlers
  },
  SET_BANNER_MAU(state, payload) {
    state.bannerMau = payload
  },
  UPDATE_POP_UP_STATUS(state, payload) {
    state.chatbotMode.popUp = payload
  },
  UPDATE_IS_EDITING_ON_CHATBOT(state, payload) {
    state.chatbotMode.isEditing = payload
  },
  UPDATE_POP_UP_TRIGGER(state, payload) {
    state.chatbotMode.trigger = payload
  },
  UPDATE_APP_CONFIGURATION(state, payload) {
    state.appConfig = payload
  },
  DISPLAY_TOAST_PIXEL(state, payload) {
    // variant: success, info, error, warning // default variant success, default position top, default duration 3000
    this._vm.$toast({
      variant: payload.variant || 'success',
      title: payload.title || '',
      position: payload.position || 'top',
      duration: payload.duration || 3000,
    })
  },
  UPDATE_SSO_CLIENT_TOKEN(state, payload) {
    state.ssoToken = payload
  },
  UPDATE_APP_CHAT_FLAG(state, payload) {
    state.isAppchatAccessible = payload
  },
  UPDATE_STICKY_TOAST(state, payload) {
    state.stickyToast.isShow = payload?.isShow
    state.stickyToast.message = payload?.message
    state.stickyToast.iconName = payload?.iconName
    state.stickyToast.iconVariant = payload?.iconVariant
    state.stickyToast.variant = payload?.variant
    state.stickyToast.duration = payload?.duration
  },
  SET_HEIGHT_BANNER_MAINTENANCE(state, payload) {
    state.heightBannerMaintenance = payload
  },
  SET_HEIGHT_BANNER_USER_STATUS(state, payload) {
    state.heightBannerUserStatus = payload
  },
  SET_HEIGHT_BANNER_CREDIT_BALANCE(state, payload) {
    state.heightBannerCreditBalance = payload
  },
  UPDATE_KB_INFO(state, payload) {
    state.kbInfo = payload
  },
}
